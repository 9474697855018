import React from "react"
import { useIntl } from "react-intl"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import PageHeader from "../components/elements/pageHeader"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const AboutPage = ({ location }) => {
  const intl = useIntl()

  return (
    <BaseStructure location={location}>
      <Seo title={intl.formatMessage({ id: "page.about.title" })} />
      <Container className="standardWidth">
        <PageHeader title={intl.formatMessage({ id: "page.about.title" })} />
        <Typography variant="h5">
          {intl.formatMessage({ id: "general.comingSoon" })}
        </Typography>
      </Container>
    </BaseStructure>
  )
}

export default AboutPage
